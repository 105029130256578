import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL, CustomApiService } from './custom-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryModel } from '../../models/query-model';
import {
  IPaymentOrganisationUpdateModel,
  PaymentOrganisation,
  PaymentOrganisationPagingResponse,
} from 'src/app/models/payment-organisation';
import { BannerStateCommand } from '../../layout/components/banner/state/banner-state.command';
import { IDuplicateResponse } from './reconciliation-api.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentOrganisationApiService extends CustomApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand: BannerStateCommand, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, bannerStateCommand, baseUrl);
  }

  public paymentOrganisationQuery(query: QueryModel): Observable<PaymentOrganisationPagingResponse> {
    return this.post<PaymentOrganisationPagingResponse>('/api/PaymentOrganisation/query', query);
  }

  public addNewPaymentOrganisation(paymentOrganisation: IPaymentOrganisationUpdateModel): Observable<PaymentOrganisation> {
    return this.post<PaymentOrganisation>('/api/PaymentOrganisation/create-new-paymentOrganisation', paymentOrganisation);
  }

  public updatePaymentOrganisation(paymentOrganisation: IPaymentOrganisationUpdateModel): Observable<PaymentOrganisation> {
    return this.post<PaymentOrganisation>('/api/PaymentOrganisation', paymentOrganisation);
  }

  public checkDuplicates(paymentOrganisation: PaymentOrganisation): Observable<IDuplicateResponse> {
    return this.post<IDuplicateResponse>('/api/PaymentOrganisation/check-duplicates', paymentOrganisation);
  }
}
